<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    size?: string;
    width?: string;
    height?: string;
    circle?: boolean;
  }>(),
  {
    width: "100%",
    height: "1em",
  },
);

const containerClass = computed(() => ({
  "v-skeleton_circle": props.circle,
}));
const containerStyle = computed(() => {
  if (props.size) return { width: props.size, height: props.size };
  else return { width: props.width, height: props.height };
});
</script>

<template>
  <div :style="containerStyle" class="v-skeleton" :class="containerClass" />
</template>

<style lang="postcss">
.v-skeleton {
  position: relative;
  overflow: hidden;
  background-color: var(--pl-skeleton-background-primary);
  border-radius: var(--pl-skeleton-radius-s);
  animation: v-skeleton-animation 2s infinite;

  &_circle {
    border-radius: 100%;
  }
}

@keyframes v-skeleton-animation {
  0% {
    background-color: var(--pl-skeleton-background-primary);
  }

  50% {
    background-color: var(--pl-skeleton-background-secondary);
  }

  100% {
    background-color: var(--pl-skeleton-background-primary);
  }
}
</style>
